var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import burger_menu from "../images/burger_menu.png";
import cross from "../images/cross.png";
var media = {
    r1200: "@media only screen and (max-width: 1200px)",
    r900: "@media only screen and (max-width: 900px)",
    r600: "@media only screen and (max-width: 600px)",
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 35px;\n    margin-top: 35px;\n    align-items: center;\n    font-family: Prima Sans;\n    font-size: 18px;\n    width: 95%;\n    margin: 0 auto;\n    ", " {\n      width: 91%;\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 35px;\n    margin-top: 35px;\n    align-items: center;\n    font-family: Prima Sans;\n    font-size: 18px;\n    width: 95%;\n    margin: 0 auto;\n    ", " {\n      width: 91%;\n    }\n"])), media.r600);
var MenuLink = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: #fff2ff;\n    text-decoration: none;\n    margin-left: 24px;\n    font-size: 22px;\n"], ["\n    color: #fff2ff;\n    text-decoration: none;\n    margin-left: 24px;\n    font-size: 22px;\n"])));
var HomeLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-decoration: none;\n  width: 8%;\n  margin-top: 20px;\n  ", "{\n      width: 16%; \n  }\n  ", "{\n      width: 22%; \n  }\n  ", "{\n      width: 34%; \n  }\n"], ["\n  text-decoration: none;\n  width: 8%;\n  margin-top: 20px;\n  ", "{\n      width: 16%; \n  }\n  ", "{\n      width: 22%; \n  }\n  ", "{\n      width: 34%; \n  }\n"])), media.r1200, media.r900, media.r600);
var MenuContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var MenuButton = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-top: 10px;\n    margin-right: 6px;\n    height: ", "px;\n    width: ", "px;\n    z-index: 2;\n"], ["\n    margin-top: 10px;\n    margin-right: 6px;\n    height: ", "px;\n    width: ", "px;\n    z-index: 2;\n"])), function (props) { return props.showDropdownMenu ? 10 : 26; }, function (props) { return props.showDropdownMenu ? 10 : 26; });
var DropdownMenu = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    position: absolute;\n    width: 150px;\n    height: 110px;\n    right: 10px;\n    top: 32px;\n    background-color: #1a191a;\n    z-index: 1;\n    text-align: center;\n"], ["\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    position: absolute;\n    width: 150px;\n    height: 110px;\n    right: 10px;\n    top: 32px;\n    background-color: #1a191a;\n    z-index: 1;\n    text-align: center;\n"])));
var DropdownMenuItem = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    color: white;\n    font-family: Prima Sans;\n    text-decoration: none;\n    margin-top: 14px;\n"], ["\n    color: white;\n    font-family: Prima Sans;\n    text-decoration: none;\n    margin-top: 14px;\n"])));
var DropdownMenuItemColor = styled.a(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    color: white;\n    font-family: Prima Sans;\n    text-decoration: none;\n    margin-top: 14px;\n    padding-top: 9px;\n    padding-bottom: 9px;\n    background-color: #471648;\n"], ["\n    color: white;\n    font-family: Prima Sans;\n    text-decoration: none;\n    margin-top: 14px;\n    padding-top: 9px;\n    padding-bottom: 9px;\n    background-color: #471648;\n"])));
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            windowWidth: window.innerWidth,
            showDropdownMenu: false,
        };
        return _this;
    }
    Menu.prototype.toggleDropdownMenu = function () {
        this.setState({ showDropdownMenu: !this.state.showDropdownMenu });
    };
    Menu.prototype.componentDidMount = function () {
        var _this = this;
        window.onresize = function () {
            _this.setState({ windowWidth: window.innerWidth });
        };
    };
    Menu.prototype.componentWillUnmount = function () {
        window.onresize = null;
    };
    Menu.prototype.render = function () {
        var _this = this;
        return (React.createElement(Container, null,
            React.createElement(HomeLink, { to: "/" },
                React.createElement(Image, { src: logo })),
            this.state.windowWidth >= 800 ? (React.createElement(MenuContainer, null,
                React.createElement(MenuLink, { href: "https://museummontanelli.com/en/about-the-museum/" }, "about"),
                React.createElement(MenuLink, { href: "https://museummontanelli.com/en/contact/" }, "contact"),
                React.createElement(MenuLink, { href: "https://museummontanelli.com/en/" }, "museummontanelli.com"))) : (React.createElement(MenuButton, { showDropdownMenu: this.state.showDropdownMenu, src: this.state.showDropdownMenu ? cross : burger_menu, onClick: function () {
                    _this.toggleDropdownMenu();
                } })),
            this.state.showDropdownMenu && this.state.windowWidth <= 800 &&
                React.createElement(DropdownMenu, null,
                    React.createElement(DropdownMenuItem, { href: "https://museummontanelli.com/en/about-the-museum/" }, "about"),
                    React.createElement(DropdownMenuItemColor, { href: "https://museummontanelli.com/en/contact/" }, "contact"))));
    };
    return Menu;
}(Component));
export default Menu;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
